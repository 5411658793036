.header-layout{
    width: 15%;
}
.navHeader{
    width: 100%;
}
.iconWebHeader{
    width: 100%;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
}